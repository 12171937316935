import { Download, File, FileText, Image, Video } from 'lucide-react';
import React, { useState, useEffect, useLayoutEffect } from 'react'; 
import { replaceUnderScoreWithSpace } from '../../../utils/Utils';


const TicketDetails = ({ticketId, fetch_single_ticket, ticket, setTicket, rerenderSingleTicket=false, renderingIn="hcc", ticketTypes, update_ticket,fetchTicketHistory}) => {
    const [isLoading, setIsLoading] = useState(false); 
    const [isRequestLoading, setIsRequestLoading] = useState({ 
        ticketType: false, 
        priority: false
    }); 

    const [editMode, setEditMode] = useState({ 
        priority: false, 
        ticketType: false
    }); 
    // const [ticket, setTicket] = useState({});

    useLayoutEffect(() => { 
        if(ticketId){ 
            fetchSingleTicket(); 
        }
    }, [ticketId, rerenderSingleTicket]);   
    

    const getFileIcon = (fileType) => {
      if (fileType.match(/\.(jpg|jpeg|png|gif|webp)$/)) return <Image className="tw-w-6 tw-h-6" />;
      if (fileType.match(/\.(mp4|webm|mov|ogg)$/)) return <Video className="tw-w-6 tw-h-6" />;
      if (fileType.match(/\.pdf$/)) return <FileText className="tw-w-6 tw-h-6" />;
      return <File className="tw-w-6 tw-h-6" />;
    };


    const getFileName = (filepath) => {
      return filepath.split('/').pop();
    };

    const getFileExtension = (filename) => {
      return filename.split('.').pop().toUpperCase();
    };


    async function  fetchSingleTicket() {
        setIsLoading(true);
        try {
            let response = await fetch_single_ticket(ticketId);

            if (response.data?.code === 200) {
                setTicket(response.data.data?.ticket);
            }
        } catch (e) {

        } finally {
            setIsLoading(false);
        }
    }


    async function updateTicket(fieldToUpdate, valueToUpdate, ticketId){ 
        setIsRequestLoading({
            ...isRequestLoading, 
            [fieldToUpdate]: true
        })
        try{ 
          let response = await update_ticket({[fieldToUpdate]: valueToUpdate, ticketId}); 
    
          if(response?.data?.code === 200){ 
            //  show toast here 
            fetchSingleTicket(); 
            fetchTicketHistory(); 
          }
        }catch(e){ 
            
        }finally { 
            setIsRequestLoading({
                ...isRequestLoading, 
                [fieldToUpdate]: false
            }); 

            setEditMode({ 
                ...editMode, 
                [fieldToUpdate] : false
            })
        }
      }


  return (
    <div className="observation-wrapper">
        <div>
            { renderingIn === "carenavigator" && <h4 className="modal-title mb-2">{ticket.title}</h4>}
        </div>
    <div className='profile-card mb-3 tw-rounded-lg tw-shadow-md border tw-border-gray-100'>
        <div className='row'>
            <div className='col-md-auto'>
                <div className="profile-image">
                    <img src={"https://appcdn.goqii.com/user/user/l_1437644704.png"} alt="Profile Image" style={{ cursor: 'pointer' }} />
                </div>
            </div>
            <div className='col-md ps-2'>
                <div className='row'>
                    <div className='col-md'>
                        <div className="profile-info">
                            <div className="info-line">
                                <div className="info">
                                    <a className="name">{ticket?.memberInfo?.memberName}</a>
                                    <span className="location">
                                        <i className="fa fa-map-marker" aria-hidden="true"></i> {ticket?.memberInfo?.city}
                                    </span>
                                </div>
                            </div>

                            <div className="info-line">
                                <div className="info">
                                    <span className="gender big-text me-3">
                                        <i className="" aria-hidden="true">
                                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/5shux/73lal8g48h.png" style={{ height: "18px" }} alt="" />
                                        </i>  {ticket?.member?.gender === "F" ? "Female" : "Male"} {ticket?.memberInfo?.age}
                                    </span>
                                    <span className="physical big-text me-3">
                                        <i className="" aria-hidden="true">
                                            <img src={ticket?.memberInfo?.imageUrl? ticket?.memberInfo?.imageUrl : "https://storage.googleapis.com/ksabupatest/2024/09/24/8u7jd/njt3shm0c4.png"} style={{ height: "18px" }} alt="" />
                                        </i> {ticket?.memberInfo?.height} cms
                                    </span>
                                    <span className="physical big-text me-3">
                                        <i className="" aria-hidden="true">
                                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f4jhy/qhl8vj7lx4.png" style={{ height: "18px" }} alt="" />
                                        </i> {ticket?.memberInfo?.weight} kgs
                                    </span>
                                    <span className="physical big-text">
                                        <i className="" aria-hidden="true">
                                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/zetj7/b4loqjyhqa.png" style={{ height: "18px" }} alt="" />
                                        </i> 22.9
                                    </span>
                                    <span className="physical big-text">
                                        <i className="" aria-hidden="true">
                                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/qem1c/m9x870b478.png" style={{ height: "18px" }} alt="" />
                                        </i> 5.4
                                    </span>
                                </div>
                            </div>
                            <div className="info-line">
                                <div className="info">
                                    <span className="email">
                                        <i className="fa fa-envelope pad-right-10" aria-hidden="true"></i> {ticket?.memberInfo?.email}
                                    </span>
                                    <span className="phone">
                                        <i className="fa fa-phone pad-right-10" aria-hidden="true"></i> {ticket?.memberInfo?.mobile}
                                    </span>
                                    { 
                                        ticket?.memberInfo?.altMobile && 
                                        <span className="phone">
                                            <i className="fa fa-phone pad-right-10" aria-hidden="true"></i> { ticket?.memberInfo?.altMobile}
                                        </span>
                                    }
                                </div>
                            </div>
                            <hr className='my-2' />
                            <div className="info-line">
                                <div className="info row">
                                    <div className='tw-flex tw-flex-row tw-items-center tw-gap-2 tw-w-1/2'>
                                        <h5 className="membership-id tw-mb-2"><b>Membership:</b> {ticket?.memberInfo?.membershipNumber}</h5>
                                    </div>
                                    <h5 className="membership-id col-6 tw-mb-0 tw-pt-2"><b>Care Navigator:</b> {ticket?.memberInfo?.carenavigatorName}</h5>
                                </div>
                            </div>
                            <div className="info-line">
                                <div className="info row">
                                    <h5 className="membership-id col-6 mb-2"><b>Main Membership:</b> {ticket?.memberInfo?.mainMembershipNo}</h5>
                                    <h5 className="membership-id col-6 mb-2"><b>Membership Type:</b> {ticket?.memberInfo?.policyMemberType}</h5>
                                </div>
                            </div>
                            <div className="info-line">
                                <div className="info row">
                                    <h5 className="dates col-6 mb-2"><b>Policy Start Date:</b> {ticket?.memberInfo?.policyStartDate}</h5>
                                    <h5 className="dates col-6 mb-2"><b>Policy End Date:</b> {ticket?.memberInfo?.policyEndDate}</h5>
                                </div>
                            </div>
                            <div className="info-line">
                                <div className="info row">
                                    <h5 className="membership-id col-6 mb-2"><b>Contract No:</b> {ticket?.memberInfo?.contractNumber}</h5>
                                    <h5 className="dates col-6 mb-2"><b>Contract Name:</b> {ticket?.memberInfo?.contractName} </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-bg-white tw-rounded-lg border tw-border-gray-100 tw-shadow-md tw-text-center tw-p-2 tw-mt-2">
        <div className="tw-bg-[#f0f4ff] tw-rounded-[14px]">
          <div className="tw-p-2">
            <div className="tw-flex tw-flex-col">
              <div className="tw-relative">
                <p className="tw-text-sm tw-text-gray-500 tw-text-left tw-mb-0">
                  Ticket Id :
                </p>
                <h1 className="tw-text-sm tw-font-semibold tw-text-start tw-font-ibm-plex tw-mb-0">
                  {ticket?.ticket_id}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-bg-[#f0f4ff] tw-rounded-[14px]">
          <div className="tw-p-2">
            <div className="tw-flex tw-flex-col">
              <div className="tw-relative">
                <p className="tw-text-sm tw-text-gray-500 tw-text-left tw-mb-0">
                  Membership No :
                </p>
                <h1 className="tw-text-sm tw-font-semibold tw-text-start tw-font-ibm-plex tw-mb-0">
                  {ticket?.membershipNo}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-bg-[#f0f4ff] tw-rounded-[14px]">
          <div className="tw-p-2">
            <div className="tw-flex tw-flex-col">
              <div className="tw-relative">
                <p className="tw-text-sm tw-text-gray-500 tw-text-left tw-mb-0">
                  Raised By :
                </p>
                <h1 className="tw-text-sm tw-font-semibold tw-text-start tw-font-ibm-plex tw-mb-0">
                  {ticket?.raisedBy}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-bg-[#f0f4ff] tw-rounded-[14px]">
          <div className="tw-p-2">
            <div className="tw-flex tw-flex-col">
              <div className="tw-relative">
                <p className="tw-text-sm tw-text-gray-500 tw-text-left tw-mb-0">
                  Status :
                </p>
                <h1 className="tw-text-sm tw-font-semibold tw-text-start tw-font-ibm-plex tw-mb-0">
                  {ticket?.status}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-bg-[#f0f4ff] tw-rounded-[14px]">
          <div className="tw-p-2">
            <div className="tw-flex tw-flex-col">
              <div className="tw-relative">
                <p className="tw-text-sm tw-text-gray-500 tw-text-left tw-mb-0">
                  Priority :
                </p>
                {editMode.priority ? (
                  <div className="d-flex align-items-center">
                    <select
                      className="form-control"
                      name="priority"
                      id="priority"
                      value={ticket?.originalPriority}
                      onChange={(e) =>
                        updateTicket(e.target.name, e.target.value, ticketId)
                      }
                    >
                      <option value="blocker">Blocker</option>
                      <option value="major">Major</option>
                      <option value="minor">Minor</option>
                      <option value="good_to_have">Good-to-have</option>
                    </select>

                    {isRequestLoading.priority ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2em"
                        height="2em"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                          opacity="0.25"
                        />
                        <path
                          fill="currentColor"
                          d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                        >
                          <animateTransform
                            attributeName="transform"
                            dur="0.75s"
                            repeatCount="indefinite"
                            type="rotate"
                            values="0 12 12;360 12 12"
                          />
                        </path>
                      </svg>
                    ) : (
                      <span
                        onClick={(e) =>
                          setEditMode({ ...editMode, priority: false })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <i className="bi bi-x-circle-fill ms-2"></i>
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <h1 className="tw-text-sm tw-font-semibold tw-text-start tw-font-ibm-plex tw-mb-0">
                      {replaceUnderScoreWithSpace(ticket?.priority)}
                    </h1>
                    <span
                      onClick={(e) =>
                        setEditMode({ ...editMode, priority: true })
                      }
                      className="ml-3"
                      style={{ marginLeft: "15px", cursor: "pointer" }}
                    >
                      <i className="bi bi-pen"></i>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="tw-bg-[#f0f4ff] tw-rounded-[14px]">
          <div className="tw-p-2">
            <div className="tw-flex tw-flex-col">
              <div className="tw-relative">
                <p className="ttw-text-sm tw-text-gray-500 tw-text-left tw-mb-0">
                  Ticket Type :
                </p>
                {editMode.ticketType ? (
                  <div className="d-flex align-items-center">
                    <select
                      className="form-control"
                      name="ticketType"
                      id="ticketType"
                      value={ticket?.originalTicketType}
                      onChange={(e) =>
                        updateTicket(e.target.name, e.target.value, ticketId)
                      }
                    >
                      {ticketTypes.map((item) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </select>

                    {isRequestLoading.ticketType ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2em"
                        height="2em"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                          opacity="0.25"
                        />
                        <path
                          fill="currentColor"
                          d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                        >
                          <animateTransform
                            attributeName="transform"
                            dur="0.75s"
                            repeatCount="indefinite"
                            type="rotate"
                            values="0 12 12;360 12 12"
                          />
                        </path>
                      </svg>
                    ) : (
                      <span
                        onClick={(e) =>
                          setEditMode({ ...editMode, ticketType: false })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <i className="bi bi-x-circle-fill ms-2"></i>
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <h1 className="tw-text-sm tw-font-semibold tw-text-start tw-font-ibm-plex tw-mb-0">
                      {ticket?.ticketType}
                    </h1>
                    <span
                      className="ml-3"
                      style={{ marginLeft: "15px", cursor: "pointer" }}
                      onClick={(e) =>
                        setEditMode({ ...editMode, ticketType: true })
                      }
                    >
                      <i className="bi bi-pen"></i>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="tw-bg-[#f0f4ff] tw-rounded-[14px]">
          <div className="tw-p-2">
            <div className="tw-flex tw-flex-col">
              <div className="tw-relative">
                <p className="tw-text-sm tw-text-gray-500 tw-text-left tw-mb-0">
                  Raised On :
                </p>
                <h1 className="tw-text-sm tw-font-semibold tw-text-start tw-font-ibm-plex tw-mb-0">
                  {ticket?.createdTime}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-bg-[#f0f4ff] tw-rounded-[14px]">
          <div className="tw-p-2">
            <div className="tw-flex tw-flex-col">
              <div className="tw-relative">
                <p className="tw-text-sm tw-text-gray-500 tw-text-left tw-mb-0">
                  Last Action Date :
                </p>
                <h1 className="tw-text-sm tw-font-semibold tw-text-start tw-font-ibm-plex tw-mb-0">
                  {ticket?.lastActionTime}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tw-w-full tw-shadow-md tw-rounded-lg border tw-border-gray-100 tw-bg-white tw-mt-4">
        <div className="tw-p-4">
          <div className="tw-mb-6">
            <h5 className="dashboard-graph-title mb-2">Description:</h5>
            <p className="tw-text-gray-700 tw-break-words tw-w-full">
              {ticket?.description}
            </p>
          </div>

          {/* <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-6"> */}
            {/* {ticket?.attachments?.map((attachment, index) => (
              <div
                className="tw-flex tw-items-center tw-gap-3 tw-shadow-md tw-rounded-lg tw-p-2 tw-bg-white border tw-border-gray-100 tw-transition-colors tw-duration-300 tw-ease-in-out"
                key={attachment.id}
              >
                <i className="bi bi-file-earmark-text tw-text-2xl"></i>
                <div className="tw-flex-1 tw-min-w-0">
                  <p className="tw-text-sm tw-font-medium tw-text-gray-900 tw-truncate tw-mb-0">
                    sample_file.txt
                  </p>
                  <p className="tw-text-xs tw-text-gray-500 tw-mb-0">File</p>
                </div>
                <a
                  href={attachment.file_path}
                  download
                  className="tw-p-2 tw-rounded-full hover:tw-bg-gray-200 tw-transition-colors"
                >
                  <i className="bi bi-download tw-w-5 tw-h-5 tw-text-gray-600"></i>
                </a>
              </div>
            ))} */}

            
<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6 tw-w-full">
  {ticket.attachments?.map((attachment, index) => {
    const isMedia = ["jpg", "jpeg", "png", "gif", "webp", "mp4", "webm", "mov", "ogg", "pdf"].includes(attachment?.ext?.toLowerCase());
    
    if (!isMedia) {
      return (
        <div key={index} className="tw-bg-gray-50 tw-rounded-lg tw-p-4 tw-shadow-md hover:tw-shadow-lg tw-transition-all tw-duration-300 border tw-border-gray-200">
          <div className="tw-flex tw-items-center tw-gap-3">
            <i className="bi bi-file-earmark tw-text-gray-400 tw-text-2xl"></i>
            <div className="tw-flex-1 tw-min-w-0">
              <p className="tw-text-sm tw-font-medium tw-text-gray-900 tw-truncate">
                {attachment.fileName}
              </p>
              <p className="tw-text-xs tw-text-gray-500 tw-uppercase">
                {attachment.ext}
              </p>
            </div>
            <a
              href={attachment.file_path}
              download
              target="_blank"
              className="tw-px-2 tw-py-1 border tw-border-gray-200 tw-rounded-full tw-bg-white/90 hover:tw-bg-white tw-shadow-md hover:tw-shadow-lg tw-transition-all tw-duration-200"
              rel="noopener noreferrer"
            >
              <i className="bi bi-download tw-text-gray-600"></i>
            </a>
          </div>
        </div>
      );
    }

    return (
      <div key={index} className="tw-group tw-relative tw-bg-white tw-rounded-lg tw-shadow-md hover:tw-shadow-lg tw-transition-all tw-duration-300 border tw-border-gray-200 tw-overflow-hidden">
        {attachment?.ext === 'pdf' ? (
          <div className="tw-h-64">
            <embed 
              src={attachment.file_path} 
              type="application/pdf" 
              className="tw-w-full tw-h-full"
            />
          </div>
        ) : ["mp4", "webm", "mov", "ogg"].includes(attachment?.ext?.toLowerCase()) ? (
          <div className="tw-relative tw-pt-[56.25%] tw-bg-gray-100">
            <video 
              controls 
              className="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-cover"
              playsInline
            >
              <source src={attachment.file_path} type={`video/${attachment.ext}`} />
              Your browser does not support this video format.
            </video>
          </div>
        ) : ["jpg", "jpeg", "png", "gif", "webp"].includes(attachment?.ext?.toLowerCase()) ? (
          <div className="tw-relative tw-pt-[75%] tw-bg-gray-100">
            <img 
              src={attachment.file_path}
              alt={`Attachment ${index + 1}`}
              className="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-cover"
              loading="lazy"
            />
          </div>
        ) : null}
        
        <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity tw-duration-200">
          <a
            href={attachment.file_path}
            download
            target="_blank"
            className="tw-px-2 tw-py-1 border tw-border-gray-200 tw-rounded-full tw-bg-white/90 hover:tw-bg-white tw-shadow-md hover:tw-shadow-lg tw-transition-all tw-duration-200"
            rel="noopener noreferrer"
          >
            <i className="bi bi-download tw-text-gray-600"></i>
          </a>
        </div>
      </div>
    );
  })}
</div>
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}

export default TicketDetails
