import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CNCalendar from '../cncalendar/CNCalendar';
import AppointmentOverview from './AppointmentOverview';

const CareNavigatorAppointments = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleTabSelect = (index) => {
        setSelectedIndex(index);
        navigate(`${location.pathname}?tab=${index}`, { replace: true });
    };
    return (
        <section className='ContentCareNavigator'>
            <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md">
                    <h1 className="main-title">CareNavigator Appointments</h1>
                </div>
                <div className="col-md text-end">

                </div>
            </div>

            <div className='reports-programs mb-3'>
                <div className="row align-items-center">
                    <div className="col-md-auto">
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f1rhk/yh4of8cegs.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
                    </div>
                    <div className="col-md">
                        <p className="mb-3">Overview of all CareNavigator appointments, including status and key metrics. Access individual CareNavigator calendars to track availability and manage schedules effectively.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row align-items-center pb-3">
                    <div className='comntent-CareNavigator mb-3'>
                        <div className="row">
                            <div className="col-md-12">
                                <Tabs selectedIndex={selectedIndex} onSelect={index => handleTabSelect(index)}>
                                    <TabList>
                                        <Tab>Appointment Overview</Tab>
                                        <Tab>CareNavigator Calendar</Tab>
                                    </TabList>

                                    <TabPanel style={{padding:"10px 0px"}}>
                                        <div className=" px-0">
                                            <AppointmentOverview />
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="px-0">
                                            <CNCalendar />
                                        </div>
                                    </TabPanel>
                                </Tabs>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CareNavigatorAppointments