import common_axios from "./commonAxios";

let baseURL = common_axios.defaults.baseURL + 'hcc/';


export const fetch_admin_user_data = () => {
    return common_axios.get(baseURL + 'fetch_admin_user_data')
};

export const hcc_user_creation_data = (data) => {
    return common_axios.post(baseURL + 'hcc_user_creation_data', data)
};

export const fetch_ad_users_list = (searchString) => {
    return common_axios.post(baseURL + 'fetch_ad_users', { searchString })
};


export const fetch_role_data = () => {
    return common_axios.get(baseURL + 'fetch_role_data')
};


export const hcc_fetch_admin_user_data = (userId) => {
    return common_axios.post(baseURL + 'hcc_fetch_admin_user_data', {userId})
};

export const hcc_admin_user_update_data = (userId, data) => {
    return common_axios.post(baseURL + 'hcc_admin_user_update_data', {...data, userId})
};  

export const fetch_all_tickets = (data) => { 
    return common_axios.get(baseURL + 'fetch_all_tickets', data);
}

export const save_ticket_type = (data) => { 
    return common_axios.post(baseURL + 'save_ticket_type', data);
}

export const fetch_all_ticket_types = () => { 
    return common_axios.get(baseURL + 'fetch_all_ticket_types');
}

export const delete_ticket_type = (ticketId) => { 
    return common_axios.post(baseURL + 'remove_ticket_type', {ticketId});
}       

export const fetch_single_ticket_type = (ticketTypeId) => {
    return common_axios.post(baseURL + 'fetch_single_ticket_type', {ticketTypeId})
}

export const edit_ticket_type = (data) => {
    return common_axios.post(baseURL + 'edit_ticket_type', data)
}

export const list_all_tickets = (data) => { 
    return common_axios.post(baseURL + 'list_all_tickets', data);
}

export const fetch_single_ticket = (ticketId) => { 
    return common_axios.post(baseURL + 'fetch_single_ticket', {ticketId});
}

export const comment_on_ticket = (data) => {  
    return common_axios.post(baseURL + 'comment_on_hcc_ticket', data);
}

export const all_hcc_agents_list = () => { 
    return common_axios.get(baseURL + 'all_hcc_agents_list');
}

export const add_new_ticket = (data) => { 
    return common_axios.post(baseURL + 'add_new_ticket', data);
}


export const fetch_member_info = (data) =>  common_axios.post(baseURL + 'fetch_member_info', data)
export const fetch_ticket_thread = (ticketId) =>  common_axios.post(baseURL + 'fetch_ticket_thread', {ticketId});
export const assign_ticket = (data) => common_axios.post(baseURL + 'assign_ticket', data);
export const update_ticket = (data) => common_axios.post(baseURL + 'update_ticket', data);
export const fetch_ticket_stats = (data) => common_axios.post(baseURL + 'fetch_ticket_stats', data);
export const fetch_ticket_history = (data) => common_axios.post(baseURL + 'fetch_ticket_history', data);
export const export_tickets = (data) => common_axios.post(baseURL + 'export_tickets', data);
export const fetch_reports = () => common_axios.get(baseURL + 'fetch_hcc_reports');
export const fetch_fileurl = (data) => common_axios.post(baseURL + 'fetch_fileurl', data); 