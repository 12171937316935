import React, { useState, useEffect } from "react";
import CalendarDatePicker from "../../componenents/CalendarDatePicker";
import { getCareNavigators, fetch_supervisors_list, carenavigatorAppointmentsOverview, carenavigatorAppointmentsOverviewGraphs } from "../../../services/medengage/Service";
import moment from "moment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CallCompetionRatesBar from "../../componenents/DashboardGraphsCNP/CallCompetionRatesBar";
import MemberPathwayStatusPie from "../../componenents/DashboardGraphs/MemberPathwayStatusPie";
import { decryptData } from "../../utils/Utils";
import { MultiSelect } from 'react-multi-select-component';
import CareNavigatorAppointmentsList from "./CareNavigatorAppointmentsList";
import CNAppointmentsBookedBy from "../../componenents/DashboardGraphs/CNAppointmentsBookedBy";
import CNAppointmentCallStatus from "../../componenents/DashboardGraphs/CNAppointmentCallStatus";
import './CareNavigatorAppointments.css';


function AppointmentOverview() {

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const formattedYesterdayDate = yesterday.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });


    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );
    const resetTime = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    const [isWithinRange, setIsWithinRange] = useState(false);
    useEffect(() => {
        const today = resetTime(new Date());
        const sd = resetTime(new Date(startDate));
        const ed = resetTime(new Date(endDate));

        console.log(today, sd, ed);

        let iwr = today >= sd && today <= ed;
        setIsWithinRange(iwr);
    }, [startDate, endDate])


    const [selectedCN, setSelectedCN] = useState("");
    const [selectedSupervisor, setSelectedSupervisor] = useState("");
    const [carenavigatorsList, setCarenavigatorsList] = useState([]);
    const [supervisorList, setSupervisorList] = useState([]);
    const [navigatorStats, setNavigatorStats] = useState({
        totalAppointmentsScheduled: 0,
        capacityUtilization: 0,
        completionRate: 0
    });
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [loading, setLoading] = useState(true);
    const [seriesGraph, setSeriesGraph] = useState({
        bookedBy: [],
        callStatus: []
    })

    const handleChange = (selected) => {
        setSelectedOptions(selected);
        const valueArray = selected.map(option => option.value).join(',')

        setSelectedCN(valueArray);
    };
    useEffect(() => {
        const performFilterEffect = async () => {
            setLoading(true);
            await filterData();
        };

        performFilterEffect();
    }, [startDate, endDate, selectedCN, selectedSupervisor]);

    useEffect(() => {
        getCareNavigatorsList();
    }, [selectedSupervisor])
    useEffect(() => {
        fetchSuperisorsList();
    }, []);

    const getCareNavigatorsList = async () => {
        setOptions([]);
        setSelectedOptions([])
        await getCareNavigators(selectedSupervisor)
            .then((response) => {
                if (response.data.code === 200) {
                    const carenavigators = response.data.data.users;
                    setCarenavigatorsList(carenavigators);
                    const formattedOptions = carenavigators.map((item) => ({
                        label: item.userName,
                        value: item.id
                    }));
                    setOptions(formattedOptions);
                }
            }).catch(function (error) {
                console.log(error);
            });
    };

    const fetchSuperisorsList = async () => {
        await fetch_supervisors_list()
            .then((res) => {
                setSupervisorList(res.data.data.supervisors);
            });
    }

    const filterData = async () => {
        await fetchData();
        await fetchMembersProgramCare();
        setLoading(false);
    };

    const fetchData = async () => {
        carenavigatorAppointmentsOverview(formatDate(startDate), formatDate(endDate), selectedCN, selectedSupervisor)
            .then((response) => {
                const statsData = response.data.data.memberData;
                setNavigatorStats(prevState => ({
                    ...prevState, totalAppointmentsScheduled: response.data.data.totalAppointmentsScheduled, capacityUtilization: response.data.data.capacityUtilization, completionRate: response.data.data.completionRate
                }));
            });
    }

    const fetchMembersProgramCare = async () => {
        carenavigatorAppointmentsOverviewGraphs(formatDate(startDate), formatDate(endDate), selectedCN, selectedSupervisor)
            .then((response) => {
                setSeriesGraph(prevState => ({
                    ...prevState, bookedBy: response.data.data.bookedBy.series.data, callStatus: response.data.data.callStatus.series.data
                }));
            });
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    const renderToolTip1 = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total appointments scheduled & direct
        </Tooltip>
    );

    const renderToolTip2 = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Capacity Utilization = Total appointments / Total slots
        </Tooltip>
    );

    const renderToolTip3 = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Completion Rate = Successful appointments / Total appointments
        </Tooltip>
    );

    const renderToolTip4 = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Distribution of appointments booked by various roles
        </Tooltip>
    );

    const renderToolTip5 = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Distribution of appointment status
        </Tooltip>
    );

    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center pb-3">
                    <div className="col-md-4">
                        <select className="form-select input-field-ui" id="selectedSupervisor" aria-label="Default select example" value={selectedSupervisor} onChange={(e) => setSelectedSupervisor(e.target.value)}>
                            <option value="">Select Supervisor</option>
                            {supervisorList.map(option => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4 px-1">
                        {/*<select className="form-select input-field-ui" id="selectedCN" aria-label="Default select example" value={selectedCN} onChange={(e) => setSelectedCN(e.target.value)}>
                            <option value="">All CareNavigators</option>
                            {carenavigatorsList.map(option => (
                                <option key={option.id} value={option.id}>{option.userName}</option>
                            ))}
                        </select>*/}
                        <MultiSelect
                            options={options}
                            value={selectedOptions}
                            onChange={handleChange}
                            labelledBy="Select"
                            hasSelectAll={false}
                            className="form-select select-input-ui select-input-ui-select"
                            disableSearch={true}
                            overrideStrings={{ selectAll: 'Select All', allItemsAreSelected: 'All items are selected', selectSomeItems: 'Select CareNavigators' }}
                            style={{ fontSize: '1rem' }}

                        />
                    </div>

                    <div className="col-md-4 ps-0">
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                    </div>
                </div>


                {
                    isWithinRange &&
                    <>
                        <div className="data-not-bg">
                            <div className="data-not-box">
                                <div className="row text-center px-2">
                                    <div className="col text-center"  >
                                        <h1 class="data-not-title">Last Updated: {formattedYesterdayDate}. Today's data will be available tomorrow.</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </>
                }

                <div className="content-ProgramPerformance mb-3">
                    <div className="row text-center px-2">
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.totalAppointmentsScheduled}</h1>
                                            <h5 className="card-text1">Total Appointments
                                                <OverlayTrigger placement="top" overlay={renderToolTip1}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.capacityUtilization}%</h1>
                                            <h5 className="card-text1">Capacity Utilization
                                                <OverlayTrigger placement="top" overlay={renderToolTip2}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.completionRate}%</h1>
                                            <h5 className="card-text1">Completion Rate
                                                <OverlayTrigger placement="top" overlay={renderToolTip3}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Appointments Booked By
                                    <OverlayTrigger placement="top" overlay={renderToolTip4}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                            </h1>
                            <div className="mt-0">
                                {loading ? (
                                    <></>
                                ) : (
                                    seriesGraph.bookedBy ? (
                                        <CNAppointmentsBookedBy series={seriesGraph.bookedBy} />
                                    ) : (
                                        <div className="emty-pie">
                                            <h5 className="emty-title-graph">There are no data to display</h5>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Call Status
                                    <OverlayTrigger placement="top" overlay={renderToolTip5}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                            </h1>
                            <div className="mt-0">
                                {loading ? (
                                    <></>
                                ) : (
                                    seriesGraph.bookedBy ? (
                                        <CNAppointmentCallStatus series={seriesGraph.callStatus} />
                                    ) : (
                                        <div className="emty-pie">
                                            <h5 className="emty-title-graph">There are no data to display</h5>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content-CareNavigatorPerformance'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive CareNavigatorAppointmentsList">
                                <CareNavigatorAppointmentsList startDate={formatDate(startDate)} endDate={formatDate(endDate)} selectedCN={selectedCN} selectedSupervisor={selectedSupervisor} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AppointmentOverview