import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CallModal from "./CallModal";
import { tm_fetch_single_user_data_list, service_call_disposition_list, service_todays_th_booking_list, telehealth_insights_list } from "../../../services/met/Service";
import { useOutletContext } from "react-router-dom";
import { initWebexCalling } from "../../../store/webex/agentWebexSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoaderLoaderConnect, LoaderLoader } from "../../utils/CommonLibrary";
import ConfirmationModal from "./components/ConfirmationModal";
import { TrendingUpDownIcon } from "lucide-react";




const MemberTeleHealth = () => {
  
  const agentWebex = useSelector(state => state.agentWebex);
  const { webexCalling } = agentWebex;

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [membershipNo, setMembershipNo] = useState("");
  const [inputError, setInputError] = useState("");
  const [singleUserDataList, setSingleUserDataList] = useState([]);
  const { callingAgent, isLoading: outletIsLoading } = useOutletContext();
  const [bookingCallHistory, setBookingCallHistory] = useState([]);
  const [bookingHistory, setBookingHistory] = useState([]); 
  const [reflect, setReflect] = useState(false);
  const [thBookings, setTHBookings] = useState([]);
  const [showCancellationConfirmationModal, setShowCancellationConfirmationModal] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const modalRef = useRef();

  // initializing webex
  useEffect(() => {

    if (callingAgent === "webex" && !webexCalling) {
      dispatch(initWebexCalling());

      return () => {
        if (webexCalling) {
          webexCalling.deregister();
        }
      }
    }
  }, [callingAgent]);


  useEffect(() => {
    fetchBookingCallHistory();
    fetchTodaysThBookingHistory(); 
  }, [reflect]);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const [analyticsData, setAnalyticsData] = useState([]);

  // validate the input 
  function vlaidateInput(input) {
    if (input === "") {
      setInputError("Please Enter Membership Number");
      return true;
    }

    return false;
  }

  // search member 
  async function searchMember(e, memberId = null) {
    e.preventDefault();

    let requestMemberId = memberId ? memberId : membershipNo;

    let isError = vlaidateInput(requestMemberId);

    if (isError) return true;
    try {
      setIsLoading(true);
      let response = await tm_fetch_single_user_data_list({ membershipNo: requestMemberId });

      if (response?.data?.code === 200) {
        setSingleUserDataList(response?.data?.data || []);
        modalRef.current?.show();
        // resett the input
        setMembershipNo("");
      } else {
        setInputError(response?.data?.data?.message || "Something went wrong");
      }
    } catch (e) {
      setInputError("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }



  function handleChange(e) {
    let regExpMember = /^\+?[\d()]*$/
    if (!regExpMember.test(e.target.value)) {
      return;
    }

    setMembershipNo(e.target.value);
    setInputError("");
  }

  function closeModal() {
    modalRef.current.hide();

  }



  async function fetchBookingCallHistory() {
    service_call_disposition_list().then((response) => {
      if (response.data?.code === 200) {
        setBookingCallHistory(response.data?.data?.history || []);
        setAnalyticsData(response.data?.data?.analyticsData || []);
      } else {

      }
    }).catch(() => {

    }).finally(() => {

    })
  }


  function todaysThBookingList() {
    service_todays_th_booking_list().then((response) => {
      setTHBookings(response?.data?.data);
    }).catch(() => { }).finally(() => {
      setIsLoading(false);
    });
  }


  function fetchTodaysThBookingHistory(){ 
    telehealth_insights_list().then((response) => {
      if (response.data?.code === 200) {
        setBookingHistory(response?.data?.data || []);
      } 
    }).catch(() => {

    }).finally(() => {
    })

  }


  function handleCancellation(bookingId, appointmentId){ 
      setShowCancellationConfirmationModal(true);
      setBookingId(bookingId); 
      setAppointmentId(appointmentId)
  }

  function handleReschedule(doctorId, doctorName, bId, aId){ 
    console.log(bId, aId); 
     modalRef.current.handleRemoteReschedule(doctorId, doctorName, bId, aId);
  }

  function handleFileUpload(userId, callId){ 
    modalRef.current.handleRemoteFileUpload(userId, callId); 
  }


  return (
    <section className="ReportsPrograms">
      <LoaderLoaderConnect isLoading={(outletIsLoading || agentWebex.isWebexRegistering)} />
      <LoaderLoader isLoading={isLoading} />
      <div className="container-fluid px-0 mb-3 ">
        <div className="row align-items-center pb-3 sticky-top-bar">
          <div className="col-md">
            <h1 className="main-title">Member Telehealth</h1>
          </div>
        </div>

        <div className="reports-programs mb-3">
          <div className="row align-items-center">
            <div className="col-md-auto">
              <img
                src="https://storage.googleapis.com/ksabupatest/2025/01/20/n4l4q/t4yhdun3ke.png"
                alt="Invited Members icon"
                style={{ height: "140px" }}
                className="img-fluid mb-0"
              />
            </div>
            <div className="col-md">
              <p className="mb-0">
              Member TeleHealth Booking allows agents to schedule Telehealth consultations for members seamlessly. This feature ensures quick and efficient appointment booking, helping members receive timely medical support.
              </p>
            </div>
          </div>
        </div>

        <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-bg-white tw-rounded-xl tw-shadow-md tw-text-center tw-py-4 tw-px-2 tw-mt-2">
          {analyticsData.map((item, index) => (
            <div key={index} className="tw-px-1">
              <div className="tw-bg-[#f0f4ff] tw-rounded-[14px]">
                <div className="tw-p-4">
                  <div className="tw-flex tw-flex-col">
                    <div className="tw-relative">
                      <h1 className="tw-text-[24px] tw-font-semibold tw-text-[#333] tw-mb-1 tw-text-start tw-font-ibm-plex">
                        {item.value}
                      </h1>
                      <p className="tw-text-sm tw-mb-0 tw-text-[#636773] tw-flex tw-items-center tw-justify-start">
                        {item.title}
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{item.tooltip}</Tooltip>}
                        >
                          <i className="bx bx-info-circle tw-ml-1 tw-cursor-pointer"></i>
                        </OverlayTrigger>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* <div className="row"> */}
          <form
            className="tw-flex tw-flex-col tw-bg-white tw-rounded-xl tw-shadow-md tw-pl-3 tw-my-3"
            method="post"
            id="searchMemberForm"
          // onSubmit={handleSubmit}
          >
            <h2 className="tw-text-lg tw-font-semibold tw-text-gray-800 tw-mt-2 tw-mb-0">
                    Search Member to book Telehealth consultation
                  </h2>
                  <div className="tw-flex tw-flex-row tw-gap-2">
                    <div className="col-md-4 mt-3 pe-0">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control input-field-ui ps-5"
                  placeholder="Search by Member ID"
                  name="membershipNo"
                  id="searchStr"
                  value={membershipNo}
                  onChange={e => handleChange(e)}
                />
                <img
                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png"
                  className="input-icon-seacrh"
                  alt=""
                />
                <p className="text-danger">{inputError}</p>
              </div>
            </div>
            <div className="col-md-1 mt-3">
              <button
                id="submitButton"
                className="btn btn-primary add-new-program-btn me-2"
                type="submit"
                onClick={e => searchMember(e)}
              >
                Search
              </button>
            </div>
                  </div>
            
          </form>
          

          <div className=" mt-3">
            <div className="tw-bg-white tw-rounded-lg tw-shadow-lg border tw-border-gray-200">
              <div className="tw-p-4">
                <div className="tw-flex tw-items-center tw-mb-4">
                  <h2 className="tw-text-lg tw-font-semibold tw-text-gray-800">
                    Telehealth Bookings 
                    <OverlayTrigger placement="top" overlay={<Tooltip>View all TeleHealth appointments booked by the agent, including appointment date, doctor details, booking time, and available actions such as rescheduling, canceling, or uploading medical records.</Tooltip>}>
                      <i className='bx bx-info-circle tw-text-gray-500 ms-1'></i>
                    </OverlayTrigger> 
                  </h2>
                </div>

                <div className="tw-overflow-x-auto tw-shadow-lg tw-rounded-lg border tw-border-gray-100">
                  <table className="tw-min-w-full tw-divide-y tw-divide-gray-200">
                    <thead>
                      <tr className="tw-bg-white">
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Member ID</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Member Name</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Appointment Date</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Telehealth Status</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Doctor Name</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Booking Time</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody className="tw-bg-white tw-divide-y tw-divide-gray-200">
                      {bookingHistory.length === 0 ? (
                        <tr className="tw-bg-[#f6f6f6] tw-text-center">
                          <td colSpan="10" className="tw-p-2 tw-text-sm tw-text-gray-400 tw-font-medium">No Data Available</td>
                        </tr>
                      ) : (
                        bookingHistory.map((item, index) => (
                          <tr key={index} className={index % 2 === 0 ? "tw-bg-[#f6f6f6]" : "tw-bg-white"}>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item.membershipNo}</td>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item.userName}</td>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item?.actionData?.appointmentDateTime}</td>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item?.actionData?.thStatus}</td>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item?.actionData?.doctorName}</td>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item?.actionData?.bookingTime}</td>
                            <td>
                            {(item?.actionData?.thStatus == 'Confirmed') && <div className={``}>
                                  <OverlayTrigger placement="top" overlay={<Tooltip>Upload Files</Tooltip>}>
                                    <button onClick={e => handleFileUpload(item.userId, item.actionData?.appointmentId)}  className="btn me-2"><i className="bi bi-upload tw-text-sm"></i></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Reschedule</Tooltip>}>
                                    <button className="btn me-2"
                                      onClick={e => handleReschedule(item.actionData?.doctorId, item.actionData?.doctorName, item?.actionData?.bookingId, item?.actionData?.appointmentId)}
                                    ><i className="bi bi-calendar4-week tw-text-sm"></i></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Cancel Booking</Tooltip>}>  
                                    <button  
                                    onClick={() => handleCancellation(item.actionData?.bookingId, item.actionData?.appointmentId)}
                                    className={`btn me-2 `}><i className="bi bi-x-lg tw-text-sm"></i></ button>
                                    </OverlayTrigger>
                                </div>}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          
          <div className="mt-3">
            <div className="tw-bg-white tw-rounded-lg tw-shadow-lg border tw-border-gray-200">
              <div className="tw-p-4">
                <div className="tw-flex tw-items-center tw-mb-4">
                  <h2 className="tw-text-lg tw-font-semibold tw-text-gray-800">
                    Call Disposition
                    <OverlayTrigger placement="top" overlay={<Tooltip>Overview of call details and outcomes, enabling effective tracking and management of each call.</Tooltip>}>
                      <i className='bx bx-info-circle tw-text-gray-500 ms-1'></i>
                    </OverlayTrigger>
                  </h2>
                </div>

                <div className="tw-overflow-x-auto tw-shadow-lg tw-rounded-lg border tw-border-gray-100">
                  <table className="tw-min-w-full tw-divide-y tw-divide-gray-200">
                    <thead>
                      <tr className="tw-bg-white">
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Member ID</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Member Name</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Main Membership No</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Call Duration</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Call Status</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Call Time</th>
                        <th className="tw-p-2 tw-text-left tw-text-sm tw-font-medium tw-text-black tw-capitalize tw-text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody className="tw-bg-white tw-divide-y tw-divide-gray-200">
                      {bookingCallHistory.length === 0 ? (
                        <tr className="tw-bg-[#f6f6f6] tw-text-center">
                          <td colSpan="10" className="tw-p-2 tw-text-sm tw-text-gray-400 tw-font-medium">No Data Available</td>
                        </tr>
                      ) : (
                        bookingCallHistory.map((item, index) => (
                          <tr key={index} className={index % 2 === 0 ? "tw-bg-[#f6f6f6]" : "tw-bg-white"}>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item.memberId}</td>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item.memberName}</td>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item.mainMembershipNo}</td>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item.callDuration}</td>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item.callStatus}</td>
                            <td className="tw-p-2 tw-text-sm tw-text-[#333] tw-font-normal">{item.callDate || "-"}</td>
                            <td>
                              <button type="button"
                                className="agent-card-subtitle1 btnn call-now-btn"
                                style={{ padding: '5px 10px !important' }}
                                onClick={e => searchMember(e, item.memberId)}
                              >
                                <span>Search</span>
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          
        {/* </div> */}
      </div>


        { 
          showCancellationConfirmationModal && (
            <div className="tw-fixed tw-inset-0 tw-bg-black/45 tw-z-[1055]">
                <ConfirmationModal
                  showCancellationConfirmationModal={showCancellationConfirmationModal} 
                  setShowCancellationConfirmationModal={setShowCancellationConfirmationModal}
                  bookingId={bookingId}
                  appointmentId={appointmentId}
                  setIsLoading={setIsLoading}
                  setReflect={setReflect}
                />
            </div>
          )
        }


      <CallModal
        isOpen={isOpen}
        handleClose={handleCloseModal}
        data={data}
        ref={modalRef}
        singleUserDataList={singleUserDataList}
        setSingleUserDataList={setSingleUserDataList}
        callingAgent={callingAgent}
        setIsLoading={setIsLoading}
        reflet={reflect}
        setReflect={setReflect}
      />

    </section>
  );
};

export default MemberTeleHealth;
