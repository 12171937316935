import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useUserTabs } from '../components/common/UserTabsContext';
import axios from "axios";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Dashboard.css'
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import TopBar from '../components/topbar/TopBar';
import UsersList from '../components/userslist/UsersList';
import Pie from '../components/graphs/Pie';
import Column from '../components/graphs/Column';
import WorkingPlansAndBreaks from '../components/workingplansandbreaks/WorkingPlansAndBreaks';
import TodaysAppointmentProgressBar from './TodaysAppointmentProgressBar';
import ContentNewReports from './ContentNewReports';
import ContentOtherTasks from './ContentOtherTasks';
import AppointmentSchedule from './AppointmentSchedule';
import { MultiSelect } from 'react-multi-select-component';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import CalendarDatePicker from '../../componenents/CalendarDatePicker';
import { fetchUpcomingAppointmentsList, fetchCallStatus, cancel_or_reschedule_appointment } from "../components/services/Service";
import './AppointmentsReport.css';

const ContentAppointments = ({ onUserClick }) => {
    const [membershipNumber, setMembershipNumber] = useState("");
    const [requestStartDate, setRequestStartDate] = useState(moment());
    const [requestEndDate, setRequestEndDate] = useState(moment().add(6, 'days'));
    const [todaysAppointmentListResult, setTodaysAppointmentListResult] = useState([]);

    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [callActionModal, setCallActionModal] = useState(false);
    const [statuses, setStatuses] = useState({});
    const [streams, setStreams] = useState([]);
    const [singleSelectStream, setSingleSelectStream] = useState(true);

    const currentTime = new Date();
    const [minTime, setMinTime] = useState(new Date());
    const [maxTime, setMaxTime] = useState(new Date());

    const [appointmentActionForm, setAppointmentActionForm] = useState({
        userId: "",
        action: "",
        reasonForCancellation: "",
        streams: [],
        slot: "",
        appointmentId: ""
    });

    const [appointmentActionFormErrors, setAppointmentActionFormErrors] = useState({
        action: "",
        reasonForCancellation: "",
        streams: "",
        slot: "",
    });

    function addDaystoDate(date, n = 21) {
        let currDate = new Date(date);
        currDate.setDate(currDate.getDate() + n);
        return currDate;
    }

    const [requestStatus, setRequestStatus] = useState({
        isCallActionLoading: false
    })

    function handleAppointmentActionChange(e) {

        if (e.target.name === "action" && e.target.value === "cancel") {
            setStreams(statuses["Cancelled"]);
        }

        setAppointmentActionForm({
            ...appointmentActionForm,
            [e.target.name]: e.target.value
        });

        setAppointmentActionFormErrors({
            ...appointmentActionFormErrors,
            [e.target.name]: ""
        });
    }

    function validateAppointmentActionForm() {
        let errors = {};

        if (appointmentActionForm.action == "") {
            errors["action"] = "Please select an action";
        }

        if (appointmentActionForm.action === "cancel") {
            if (appointmentActionForm.reasonForCancellation == "") {
                errors["reasonForCancellation"] = "Please enter reason for cancellation";
            }

            if (appointmentActionForm.streams?.length <= 0) {
                errors['streams'] = "Please select at least one stream";
            }
        }


        if (appointmentActionForm.action === "re-schedule") {
            if (appointmentActionForm.slot == "") {
                errors["slot"] = "Please enter date and time to reschedule";
            }
        }

        return errors;
    }

    const getCallStatus = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchCallStatus(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setStatuses(response.data.data.status);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    function formatDateTime(date) {
        if (!date) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    async function saveAppointmentAction() {
        let errors = validateAppointmentActionForm();

        if (Object.keys(errors).length > 0) {
            setAppointmentActionFormErrors({ ...appointmentActionFormErrors, ...errors });
            return;
        }

        setRequestStatus({ ...requestStatus, isCallActionLoading: true });

        try {
            let response = await cancel_or_reschedule_appointment({ ...appointmentActionForm, slot: formatDateTime(appointmentActionForm.slot), streams: appointmentActionForm.streams, userId: appointmentActionForm.userId });

            if (response.data.code === 200) {
                //props.callCallsHistory();
                setAppointmentActionFormErrors({
                    ...appointmentActionFormErrors,
                    action: "",
                    reasonForCancellation: "",
                    streams: "",
                    slot: ""
                });
                setAppointmentActionForm({
                    ...appointmentActionForm,
                    action: "",
                    reasonForCancellation: "",
                    streams: [],
                    slot: "",
                    appointmentId: ""
                });

                //getUserSpecificCalls();
                setTodaysAppointmentListResult([])
                getTodaysAppointmentsList(membershipNumber, formatDate(requestStartDate), formatDate(requestEndDate), 1)
                setCallActionModal(false);
            } else {

            }
        } catch (error) {

        } finally {
            setRequestStatus({ ...requestStatus, isCallActionLoading: false });
        }

    }

    /*useEffect(() => {
        getTodaysAppointmentsList();
    }, [])*/

    useEffect(() => {
        getCallStatus();
    }, [])

    useEffect(() => {
        getTodaysAppointmentsList(membershipNumber, formatDate(requestStartDate), formatDate(requestEndDate), pageNumber);
    }, [pageNumber])

    useEffect(() => {
        setPageNumber(1);
        setTodaysAppointmentListResult([]);
        getTodaysAppointmentsList(membershipNumber, formatDate(requestStartDate), formatDate(requestEndDate), 1);
    }, [membershipNumber, requestStartDate, requestEndDate])


    const observer = useRef();
    const lastUserElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore]);


    const handleDateChange = ({ start, end }) => {
        setPageNumber(1);
        setRequestStartDate(start);
        setRequestEndDate(end);
        getTodaysAppointmentsList(membershipNumber, formatDate(start), formatDate(end), 1);
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const getTodaysAppointmentsList = async (membershipNumber, startDate, endDate, pageNumber) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchUpcomingAppointmentsList(membershipNumber, startDate, endDate, pageNumber, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    //setTodaysAppointmentListResult(response.data.data.appointments);

                    setTodaysAppointmentListResult(prevRecords => {
                        const newRecords = (response.data.data.appointments).filter(appointment =>
                            !prevRecords.some(existingAppointment => existingAppointment.callId === appointment.callId)
                        );
                        return [...prevRecords, ...newRecords];
                    });

                    if (response.data.data.appointments.length > 0) setHasMore(true);
                    else setHasMore(false);
                    setLoading(false);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }
    return (
        <>
            <div className='container-fluid'>
                <Row>
                    <Col md={4}><span style={{ backgroundColor: "transparent", padding: "2px", border: "none", fontWeight: "500", fontSize: "14px" }}>Membership #</span></Col>
                    <Col className='ps-0'><span style={{ backgroundColor: "transparent", padding: "2px", border: "none", fontWeight: "500", fontSize: "14px" }}>Date</span></Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <input type="input" name="membershipNumber" value={membershipNumber} onChange={(e) => {
                            if (!/^\d*\.?\d*$/.test(e.target.value)) {
                                return false;
                            }
                            setMembershipNumber(e.target.value);
                            getTodaysAppointmentsList(e.target.value, formatDate(requestStartDate), formatDate(requestEndDate), 1);
                        }
                        } className='form-control control-input-ui-dashboard' />
                    </Col>
                    <Col>
                        <CalendarDatePicker startDate={requestStartDate} endDate={requestEndDate} onDateChange={handleDateChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            todaysAppointmentListResult.length <= 0 &&
                            `No records found`
                        }
                    </Col>
                </Row>
                <div className='row'>
                    <div className='col-md-12 px-0 mt-2' style={{ maxHeight: "392px", overflowX: "hidden", overflowY: "scroll" }}>
                        {
                            todaysAppointmentListResult.map((item, index) => {
                                if (todaysAppointmentListResult.length === index + 1) {
                                    return (
                                        <div ref={lastUserElementRef} key={index} className='row mt-3'>
                                            <div className='col-auto pt-2'>
                                                <i class="fa fa-bell mx-2" style={{ color: "#FEB558", fontSize: "20px" }} aria-hidden="true"></i>
                                            </div>
                                            <div className='col ps-0' style={{fontSize:"14px"}}>
                                                Appointment Scheduled with <b><Link to={'/members/' + encodeURIComponent(item.userId, "UTF-8")} className=''   onClick={() => onUserClick({ userId: item.userId, name: item.memberName })} style={{fontSize:"14px !importantx"}}><b style={{fontSize:"14px !important"}}>{item.memberName}</b></Link></b> on <b >{item.date}</b> at <b>{item.time}</b>

                                            </div>
                                            <div className='col-auto'>
                                                <Button variant="primary"  className="badge rounded-pill btn btn-outline-secondary add-new-member-btn" type="button" onClick={() => {
                                                    setCallActionModal(true);
                                                    setAppointmentActionForm({
                                                        ...appointmentActionForm,
                                                        appointmentId: item.callId,
                                                        userId: item.userId
                                                    })
                                                }}
                                                >Re-schedule / Cancel</Button>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index} className='row mt-3'>
                                            <div className='col-auto pt-2'>
                                                <i class="fa fa-bell mx-2" style={{ color: "#FEB558", fontSize: "20px" }} aria-hidden="true"></i>
                                            </div>
                                            <div className='col ps-0' style={{fontSize:"14px"}}>
                                                Appointment Scheduled with <b><Link to={'/members/' + encodeURIComponent(item.userId, "UTF-8")} className=''  onClick={() => onUserClick({ userId: item.userId, name: item.memberName })} style={{fontSize:"14px !importantx"}}><b style={{fontSize:"14px !important"}}>{item.memberName}</b></Link></b> on <b>{item.date}</b> at <b>{item.time}</b>
                                                
                                            </div>
                                            <div className='col-auto'>
                                                <Button variant="primary" className="badge rounded-pill btn btn-outline-secondary add-new-member-btn" type="button" onClick={() => {
                                                    setCallActionModal(true);
                                                    setAppointmentActionForm({
                                                        ...appointmentActionForm,
                                                        appointmentId: item.callId
                                                    })
                                                }}
                                                >Re-schedule / Cancel</Button>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>

            <Modal id="callActionModal" show={callActionModal} centered
                onHide={() => {
                    setCallActionModal(false);
                    setAppointmentActionForm({
                        ...appointmentActionForm,
                        action: "",
                        reasonForCancellation: "",
                        streams: [],
                        slot: "",
                        appointmentId: ""
                    });

                    setAppointmentActionFormErrors({
                        action: "",
                        reasonForCancellation: "",
                        streams: "",
                        slot: ""
                    });
                }} size="lg" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title' style={{ width: "100%" }}>Re-schedule / Cancel Appointment</h1>
                </Modal.Header>
                <Modal.Body className='disposition_from'>
                    <Row>
                        <Col sm={12}>
                            <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Action <span class="text-danger">*</span></h2>
                        </Col>
                        <Col md="auto">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="action" value="re-schedule" onClick={handleAppointmentActionChange} />
                                <label class="form-check-label" >Re-Schedule Appointment</label>
                            </div>
                        </Col>
                        <Col md="auto">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="action" value="cancel" onClick={handleAppointmentActionChange} />
                                <label class="form-check-label" >Cancel Appointment</label>
                            </div>
                        </Col>

                        {appointmentActionFormErrors.action && <p style={{ color: "red" }}>{appointmentActionFormErrors.action}</p>}
                    </Row>

                    {
                        appointmentActionForm.action === "cancel" &&
                        <>

                            <Row className='mt-4'>
                                <Col sm={12}>
                                    <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Stream <span class="text-danger">*</span></h2>
                                </Col>
                                <Col>
                                    <MultiSelect
                                        options={streams}
                                        value={appointmentActionForm.streams}
                                        onChange={e => {
                                            setAppointmentActionForm({
                                                ...appointmentActionForm,
                                                streams: e
                                            });

                                            setAppointmentActionFormErrors({
                                                ...appointmentActionFormErrors,
                                                streams: ""
                                            });
                                        }}
                                        labelledBy="Select"
                                        hasSelectAll={false}
                                        disableSearch={false}
                                        singleSelect={setSingleSelectStream}
                                        overrideStrings={{
                                            selectAll: 'Select All',
                                            allItemsAreSelected: 'All Streams are selected',
                                            selectSomeItems: 'Select Streams',
                                        }}
                                        className="form-select select-input-ui select-input-ui-select"
                                        name="callStreams form-select input-field-ui"
                                        id="callStreams"
                                    />
                                </Col>
                                {appointmentActionFormErrors.streams && <p style={{ color: "red", fontWeight: "300" }}>{appointmentActionFormErrors.streams}</p>}
                            </Row>
                            <Row className='mt-4'>
                                <Col sm={12}>
                                    <h2 class="module-card-title mb-2" style={{ fontSize: "16px", fontWeight: "300" }}>Reason for Cancellation <span style={{ color: "red" }}>*</span></h2>
                                    <textarea className='form-control control-input-ui' value={appointmentActionForm.reasonForCancellation} name="reasonForCancellation" onChange={handleAppointmentActionChange}></textarea>
                                </Col>
                                {appointmentActionFormErrors.reasonForCancellation && <p style={{ color: "red" }}>{appointmentActionFormErrors.reasonForCancellation}</p>}

                            </Row>

                        </>
                    }


                    {
                        appointmentActionForm.action === "re-schedule" &&
                        <>
                            <Row>
                                <Col sm={12} className='mt-2'>
                                    <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Follow-up Call Date & Time <span class="text-danger">*</span></h2>
                                </Col>
                                <Col>
                                    <DatePicker
                                        selected={appointmentActionForm.slot}
                                        onChange={updatedDate => {
                                            setAppointmentActionForm({ ...appointmentActionForm, slot: updatedDate });
                                            setAppointmentActionFormErrors({
                                                ...appointmentActionFormErrors,
                                                slot: ""
                                            });
                                        }}
                                        showTimeSelect
                                        timeIntervals={15}
                                        minDate={currentTime}
                                        maxDate={addDaystoDate(currentTime, 365)}
                                        minTime={appointmentActionForm.slot && appointmentActionForm.slot > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date()}
                                        maxTime={new Date().setHours(23, 59, 59)}

                                        timeInputLabel="Time:"
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        placeholderText="Select Date and Time"
                                        className='form-select input-field-ui'
                                        onKeyDown={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                    />
                                    {appointmentActionFormErrors.slot && <p style={{ color: "red" }}>{appointmentActionFormErrors.slot}</p>}
                                </Col>
                            </Row>
                        </>
                    }
                    <Row className='mt-3'>
                        <Col>
                            <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                            <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>

                            <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={saveAppointmentAction}
                                disabled={requestStatus.isCallActionLoading ? "disabled" : ""}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal >
        </>
    );
};

export default ContentAppointments;
