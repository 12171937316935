import React, {useState} from 'react'; 
import { useNavigate } from 'react-router-dom';
import PageDescription from '../../Components/PageDescription/PageDescription';
import AddTicketForm from '../../Components/AddTicketForm/AddTicketForm';
import { add_new_ticket, fetch_all_ticket_types } from '../../../../services/hcc/Service';


const RaiseNewTicket = () => {
 const navigate = useNavigate(); 
 

 function navigateToBack() { 
    navigate(-1);
 }


  return (
    <div className='container-fluid'>
         <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md" style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <button className="btn btn-primary back-btn mb-0 me-3" onClick={navigateToBack}><i className="bi bi-chevron-left me-1"></i> Back</button>
                <div className="col"> 
                    <h4 className="mb-0 content-main-title">Add New Ticket</h4>
                </div>
            </div>      
        </div>

        <PageDescription text={"Choose an observation type, set priority, and add a clear description. Attach any helpful screenshots or logs. More details mean faster resolutions!"} image={"https://storage.googleapis.com/ksabupatest/2024/11/21/l4qs7/32llgolhd4.jpg"}/>

        <div className='comntent-UserManagement'>   
            <AddTicketForm  membershipNo={"12345678"} raisedBy={"hcc_agent"} add_new_ticket={add_new_ticket} fetch_all_ticket_types={fetch_all_ticket_types} />
        </div>
    </div>
  )
}

export default RaiseNewTicket
