import React, { useState, useEffect } from 'react'
import PageDescription from '../../Components/PageDescription/PageDescription';
import Table from '../../../cn/components/Tables/ServerSideSortableDataTable';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { list_all_tickets, all_hcc_agents_list, assign_ticket, fetch_ticket_stats, export_tickets } from '../../../../services/hcc/Service';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setTicketPagination, setTicketPerPage } from '../../../../store/paginationSlice';
import { setFilter, setSortConfig } from '../../../../store/fitler/hccTicketFilter';
import CalendarDatePicker from '../../../componenents/CalendarDatePicker';
import moment from 'moment';
import { decryptData } from '../../../utils/Utils';
import PriorityDistribution from '../../Components/Graphs/PriorityDistributino';
import { toast, ToastContainer } from 'react-toastify';

const AllTickets = () => {

  const paginationInfo = useSelector(state => state.pagination.hccTickets);
  const sortConfig = useSelector(state => state.hccTicketFilter.sort);
  const filter = useSelector(state => state.hccTicketFilter.filter);
  const [isLaoding, setIsLoading] = useState(false);
  const [allAgents, setAllAgents] = useState([]);
  const [userId, setUserId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [tickets, setTickets] = useState([]);
  const [graph, setGraph] = useState({ 
    status: {}, 
    priority: {}
  })

  const { ticketId, ticketStatus, priority, agent, dates } = filter;
  const { startDate, endDate } = dates;

  const storedDates = localStorage.getItem("selectedDates")
    ? JSON.parse(decryptData(localStorage.getItem("selectedDates")))
    : "";

  const handleDateChange = ({ start, end }) => {
    dispatch(setFilter({ ...filter, dates: { startDate: start, endDate: end } }));
  };

  const dispatch = useDispatch();

 

  const columns = [
    {
      name: (
        <span>Ticket ID</span>
      ),
      selector: 'ticket_id',
      sortable: true,
    },
    {
      name: (
        <span>Title</span>
      ),
      selector: 'title',
      sortable: false,
    },
    {
      name: (
        <span>Status</span>
      ),
      selector: 'status',
      sortable: true,
    },
    {
      name: (
        <span>Priority</span>
      ),
      selector: 'priority',
      sortable: true,
    },
    {
      name: (
        <span>Ticket Type</span>
      ),
      selector: 'ticketType',
      sortable: true,
    },
    {
      name: (
        <span>Raised On Date</span>
      ),
      selector: 'createdTime',
      sortable: true,
    },
    {
      name: (
        <span>Last Action Date</span>
      ),
      selector: 'lastActionTime',
      sortable: true,
    },
    {
      name: (
        <span>Raised By</span>
      ),
      selector: 'raisedBy',
      sortable: true,
    },
    {
      name: (
        <span>Assigned To</span>
      ),
      key: "assign_to",
      cell: row => (
        row?.assignedTo === null ?
          <td className="tw-border-none">
            <button type="button" className="agent-card-subtitle1 btn call-now-btn"
              style={{ padding: '5px 10px !important' }}
              onClick={() => assignTicket(row.encTicketId, userId)}
            >
              <span className='tw-text-nowrap'>Assign to Me</span>
            </button>
          </td> :
          <td  className='tw-border-none'>
            {row?.assignedTo}
          </td>
      )
    },
    {
      name: (
        <span>Options</span>
      ),
      cell: row => (
        <div>
          <Link style={{ textDecoration: 'none' }} to={`/view-ticket/${row.encTicketId}`} className='mb-0 me-2 tw-text-black'>
            <i className="fa fa-eye"></i>
          </Link>
        </div>
      ),
    }
  ];

  

  useEffect(() => {
    fetchAllHccAgents();
  }, []);

  useEffect(() => {
    fetchAllTickets();
    ticketsStats(); 
  }, [sortConfig.direction, sortConfig.field, paginationInfo.currentPage, paginationInfo.perPage, ticketId, ticketStatus, agent, startDate, endDate]);

  useEffect(() => {
    let timer;

    if(searchQuery != ""){ 
      timer = setTimeout(() => {
        dispatch(setFilter({ ...filter, ticketId: searchQuery }))
      }, 300)
    }

    return () => clearTimeout(timer);
  }, [searchQuery])

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    dispatch(setFilter({ ...filter, [name]: value }));
  }

  const handleClear = () => {
    dispatch(setFilter({
      ticketId: "",
      ticketStatus: "",
      agent: "",
      dates: {
        startDate: moment(storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()),
        endDate: moment(storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment())
      }
    }))

    dispatch(setSortConfig({
      field: null,
      direction: 'asc'
    }));

    setSearchQuery('');
  }

  const handleExport = async () => {
     try{   
      setIsLoading(true);
      let response = await export_tickets({ 
        status: ticketStatus,
        priority: priority,
        field: sortConfig.field,
        direction: sortConfig.direction,
        searchQuery: ticketId,
        agent: agent,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      }); 

      if(response?.data?.code === 200){ 
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }else { 
        toast.error(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }

     }catch(e){ 

     }finally{ 
      setIsLoading(false);
     }
  }

  async function fetchAllTickets() {
    setIsLoading(true);
    try {
      const response = await list_all_tickets({
        perPage: paginationInfo.perPage,
        currentPage: paginationInfo.currentPage,
        status: ticketStatus,
        priority: priority,
        field: sortConfig.field,
        direction: sortConfig.direction,
        searchQuery: ticketId,
        agent: agent,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      });

      if (response.status === 200) {
        setTickets(response.data.data?.tickets || []);
        dispatch(setTicketPagination({ ...paginationInfo, totalPages: response?.data?.data?.totalPages, totalEntries: response?.data?.data?.totalEntries }));
      }
    } catch (e) {

    } finally {
      setIsLoading(false);
    }
  }


  const updateSortConfig = (field, direction, isSortable) => {
    if (!isSortable) return;
    dispatch(setSortConfig({ field: field, direction: direction }));
  }


  async function fetchAllHccAgents() {
    try {
      let response = await all_hcc_agents_list();

      if (response.data.code === 200) {
        setAllAgents(response?.data?.data?.agents);
        setUserId(response?.data?.data?.agentId);
        dispatch(setFilter({ ...filter, agent: response?.data?.data?.agentId }));
      }
    } catch (e) {

    }
  }



  function setPaginationInfo(info) {
    dispatch(setTicketPagination(info));
  }

  function setPerPage(perPage) {
    dispatch(setTicketPerPage(perPage));
  }

  async function assignTicket(ticketId, userId) {
    console.log(userId);
    setIsLoading(true);
    try {
      let response = await assign_ticket({ ticketId, userId });

      if (response.data.code === 200) {
        fetchAllTickets();
      }
    } catch (e) {

    } finally {
      setIsLoading(false);
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  async function ticketsStats(){ 
    try {
      let response = await fetch_ticket_stats({ 
        perPage: paginationInfo.perPage,
        currentPage: paginationInfo.currentPage,
        status: ticketStatus,
        priority: priority,
        field: sortConfig.field,
        direction: sortConfig.direction,
        searchQuery: ticketId,
        agent: agent,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      });
      if(response.data.code === 200){
        setGraph({...graph, status: response?.data?.data?.status, priority: response?.data?.data?.priority}); 
        
      }
    } catch (e) {
      console.log(e);
    }
  }

  

  return (
    <div className='container-fluid hcc-dashboard-page'>
      <ToastContainer />
      <div className="sticky-top-bar tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full tw-pb-3">
        <div className="col-md">
          <h4 className="mb-0 content-main-title">All Tickets</h4>
        </div>
        <div className="col-md">

        </div>

        <div className="tw-flex tw-flex-row tw-items-center tw-w-[30%] tw-gap-2">
          {/* <Link to={`/raise-new-ticket`} className="btn btn-primary add-new-program-btn tw-w-[50%]">
            <i className="bi bi-person-plus-fill me-2"></i> Raise New Ticket
          </Link> */}

          <CalendarDatePicker
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
          />
        </div>
      </div>

      {/* ------------------------- Page Description --------------------- */}
      <PageDescription text={"Manage and act on tickets raised by CareNavigators. Track ticket progress, review comments, and ensure timely resolutions to improve member experience."} image={"https://storage.googleapis.com/ksabupatest/2025/01/21/bh516/sj44wowlv1.png"} />



      {/* ------------------- All tickets section =------------------------- */}
      <div className='comntent-UserManagement'>
        <div className='container-fluid'>
          <div className='row mb-3'>

            {/* Search Field */}
            <div className='col-md-4 px-0'>
              <div className="form-group">
                <input
                  type="text" 
                  name="ticketId"
                  placeholder="Search By Ticket ID"
                  className="form-control input-field-ui ps-5 w-100"
                  value={searchQuery}
                  onChange={e => {
                    if (!/^\+?[\d()]*$/.test(e.target.value)) {
                      return;
                    }
                    setSearchQuery(e.target.value)
                  }}
                />
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
              </div>
            </div>

            {/* Status Dropdown */}
            <div className='col-md-3 px-1'>
              <select
                name="ticketStatus"
                className='form-select input-field-ui w-100'
                value={ticketStatus}
                onChange={e => handleFilterChange(e)}
              >
                <option value="">--Select Status--</option>
                <option value="new">New</option>
                <option value="assigned">Assigned</option>
                <option value="resolved">Resolved</option>
                <option value="reopen">Reopen</option>
                <option value="closed">Closed</option>
              </select>
            </div>

            {/* Priority Dropdown */}
            <div className='col-md-3 px-1'>
              <select
                name="agent"
                className='form-select input-field-ui w-100'
                value={agent}
                onChange={e => handleFilterChange(e)}

              >
                <option value="">--Select Assigned To--</option>
                {
                  allAgents.map((agent, index) => {
                    return <option key={index} value={agent.id}>{agent.name}</option>
                  })
                }
              </select>
            </div>

            {/* Clear Filters Button */}
            <div className='col-md-1 px-1'>
              <button className='btn btn-primary btn-primary clear-search-btn w-100' onClick={handleClear}>
                Clear
              </button>
            </div>

            {/* Export Button */}
            <div className='col-md-1 px-0'>
              <button className='btn btn-primary btn-primary clear-search-btn w-100' onClick={handleExport}>
                Export
              </button>
            </div>
          </div>
        </div>


        <div className='data-points'>
          <div className='row'>
            <div className="col-md-6 mb-3">
              <div className="dashboard-graph-box mb-3">
                <h1 className='dashboard-graph-title '>
                  <span>Ticket Status
                    <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Overview of tickets based on their current status. Easily monitor new and resolved tickets.</Tooltip>}>
                      <i className='bx bx-info-circle ms-1'></i>
                    </OverlayTrigger>
                  </span>
                </h1>

                <PriorityDistribution data={graph.status || {}} title={'Call Status'} />

              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="dashboard-graph-box mb-3">
                <h1 className='dashboard-graph-title '>
                  <span>Ticket Priority
                    <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Overview of ticket priorities Blocker, Major, or Minor to help agents address critical issues first.</Tooltip>}>
                      <i className='bx bx-info-circle ms-1'></i>
                    </OverlayTrigger>
                  </span>
                </h1>

                <PriorityDistribution data={graph.priority || {}} title={'Call Status'} />

              </div>
            </div>
          </div>
        </div>


        <Table
          columns={columns}
          data={tickets}
          sortConfig={sortConfig}
          paginationInfo={{ ...paginationInfo, totalRecords: paginationInfo.totalEntries }}
          isLoading={isLaoding}
          updateSortConfig={updateSortConfig}
          setPaginationInfo={setPaginationInfo}
          maxStringChars={200}
          setPerPage={setPerPage}
          userId={userId}
        />
      </div>
    </div>
  )
}

export default AllTickets;  